import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { Sesion } from '../interfaces/Sesion.interface';
import { AlumnoInfo } from '../interfaces/AlumnoInfo.interface';
import { Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { PushNotifications } from '@capacitor/push-notifications';
import { FirebaseService } from 'src/app/pages/services/firebase.service';
import { Token } from '../interfaces/token.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  newToken: any;
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient, private storage: Storage, private router: Router,private firebaseService:FirebaseService) { }

  // Generar token
  login(params) {
    return this.http.post<Sesion>(`${environment.phpWebApi}/api/Authentication/GenerateToken.php`, params, this.httpOptions);
  }

  // Obtener informacion del alumno
  getInfoAlumno(paramsAlumno, token) {

    console.log(paramsAlumno);
    console.log(token);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post<AlumnoInfo>(`${environment.phpWebApi}/api/Alumnos/InformacionAlumnos.php`, paramsAlumno, httpOptions);
  }
  getAlumnosPorFamilia(token){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':'Bearer '+  token.Token
        })
      }
      const params = {
        param:{
              familia: token.familia
            }
        }
        console.log('Familia: ',token.familia)
      return this.http.post(`${environment.phpWebApi}/api/Alumnos/AlumnosPorUsuario.php`, JSON.stringify(params), httpOptions)
  }

  /**
   * Funcion para obtener todos los miembros de una familia
   * @param token Variable token del local host, esta variable es todo, no solo el token de acceso
   * @returns Retorna una promesa con la lista de alumnos de la familia correspondiente
   */
  async getAlumnosPorFamiliaArray(token){
    try {
      const alumnosPorFamilia:any = await lastValueFrom(this.getAlumnosPorFamilia(token));
      const observables = alumnosPorFamilia.resonse.result.map(element => {
        const paramsAlumno = { param: { codigo: element.Codigo } };
        return this.getInfoAlumno(paramsAlumno, token.Token);
      });
  
      const alumnos:any = await lastValueFrom(forkJoin(observables));
      const resultados = alumnos.map(alumno => alumno.resonse.result);
  
      return resultados;
    } catch (error) {
      throw error;
    }
    // let observables = []
    // let promise = new Promise<any>((resolve,reject)=>{
    //   this.getAlumnosPorFamilia(token).subscribe((x:any)=>  {
    //     const paramsAlumno ={
    //       param: { codigo: '' }
    //     };
    //     x.resonse.result.forEach(element => {
    //       paramsAlumno.param.codigo = element.Codigo
    //       let o = this.getInfoAlumno(paramsAlumno,token.Token)
    //       observables.push(o)
    //     }); 

    //     forkJoin(observables).subscribe((alumnos) => {
    //       var l = alumnos.map(m=> m.resonse.result)
    //       resolve(l)
    //     });
    //   },error=>{
    //     reject(error)
    //   })
    // })

    // return await promise

  }

  // Cerrar sesion
  signOut() {
    var token:Token = JSON.parse(localStorage.getItem('token'));
    console.log("Cerrando Sesion: ",token.usuario )
    this.firebaseService.removeDocument("NotificationTokens","usuario",token.usuario)
    PushNotifications.unregister()
    localStorage.clear();
    this.storage.clear();
    this.router.navigate(['./auth']);

  }

   /*---------| Verificar autenticacion |---------*/
   verifyAuth(): Observable<boolean> {
    if (!localStorage.getItem('token')) {
      return of(false);
    }

    return of(true);
  }

  firebasePushNotificationsRegistration(){
    PushNotifications.addListener('registration', (token:any) => {
      var t:Token = JSON.parse(localStorage.getItem('token'))
      console.log('Push registration success, token: ' + token.value);
      
      var obj = { 
        usuario: t.usuario,
        codigo: t.codigo,
        familia: t.familia,
        Perfil: t.Perfil,
        curso:t.curso,
        nombre: "",
        alumnos:null,
        token:token.value
      }
    // this.firebaseService.documentExist("NotificationTokens","token",obj.token).then(empty=>{
        var firebaseToken:string = JSON.parse(localStorage.getItem('FirebaseToken'));
        if(firebaseToken == null)
        {
          localStorage.setItem('FirebaseToken', obj.token);

          const paramsAlumno ={
            param: { codigo: t.codigo }
          };
          this.getInfoAlumno(JSON.stringify(paramsAlumno), t.Token)
          .subscribe((alumnInfo: AlumnoInfo) => {
            obj.curso = t.curso
            this.firebaseService.documentExist("NotificationTokens","token",obj.token).then(async (empty)=>{
                if(empty)
                {
                  if (obj.Perfil == "Padres de Familia")
                  {
                    var alumnos = await this.getAlumnosPorFamiliaArray(t)
                    obj.nombre = alumnInfo.resonse.result.Nombre
                    obj.alumnos = alumnos
                  }
                  this.firebaseService.addDocument(obj,"NotificationTokens")
                }
              })
          })
        }
      //})
      //alert('Push registration success, token: ' + token.value);
      
    });
    }

}
