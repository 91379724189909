import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { getToken } from '@angular/fire/messaging';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { MessagesService } from './auth/services/messages.service';
import { FirebaseService } from './pages/services/firebase.service';
import { AuthService } from './auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
  , styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private platform: Platform, private storage: Storage, private messagesServ: MessagesService,
    private firebaseService:FirebaseService,private authService:AuthService,private router:Router) {
    this.platform.ready().then(_ => {
    });
    this.initializeApp();
    
  }
  
  ngOnInit() {
    console.log('Initializing HomePage');


    // //this.firebaseService.requestPermission()
    // PushNotifications.addListener('registration', (token: Token) => {
      
    //   console.log('Push registration success, token: ' + token.value);
    //   //this.firebaseService.addDocument(token,"tokens")
    //   //alert('Push registration success, token: ' + token.value);
      
    // });

    this.authService.firebasePushNotificationsRegistration()

    PushNotifications.addListener('registrationError', (error: any) => {
      //alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log(notification)
       this.messagesServ.showAlert(notification.title,"",notification.body,"Ir",x=>{
        this.router.navigate(['/pages/tabs/tab2']);
       })


      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.router.navigate(['/pages/tabs/tab2']);

        //alert('Push action performed: ' + JSON.stringify(notification));
      },
    );

    if (localStorage.getItem('token')) {
      this.firebaseService.requestPermission()
    }
  }

  async initializeApp() {
    await this.storage.create();

    //this.messagesServ.requestPermission();
  }
  
}
