import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const environment = {
  production: false,
  phpWebApi: 'https://www.sanjosefilmmakers.com/phpapi',
  //phpWebApi: 'http://localhost:8000',
  firebaseConfig: {
    apiKey: 'AIzaSyDLI5WnKVXpJKOtrkzYGuPe-0f4bTC3mmg',
    authDomain: 'exeranitest.firebaseapp.com',
    projectId: 'exeranitest',
    storageBucket: 'exeranitest.appspot.com',
    messagingSenderId: '718432523725',
    appId: '1:718432523725:web:f9346fe4a3d4a8f87f2084',
    measurementId: 'G-5L3F4DL33W' 
  }
};

firebase.initializeApp(environment.firebaseConfig);

export default firebase.firestore();


