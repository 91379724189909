import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { environment } from 'src/environments/environment';
import { AlumnoInfo } from '../interfaces/AlumnoInfo.interface';
import { Sesion } from '../interfaces/Sesion.interface';
import { Observable, Subject, of } from 'rxjs';
import { Alumno } from '../interfaces/alumno.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlumnosService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private alumno$:Subject<Alumno>

  constructor(private http: HttpClient, private authService:AuthService,private storage: Storage) { 
    this.alumno$ = new Subject();
  }

  /*---------| Iniciar la sesion |---------*/
  signIn(params) {
    return this.http.post<Sesion>(`${environment.phpWebApi}/api/Authentication/GenerateToken.php`, params, this.httpOptions);
  }

  getInfoAlumno(params, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post<AlumnoInfo>(`${environment.phpWebApi}/api/Alumnos/InformacionAlumnos.php`, params, httpOptions);
  }

  /*----------| Guardar informacion del amuno |---------*/
  saveInfoAlumno(alumnoInfo: AlumnoInfo) {
    this.storage.set('alumno', alumnoInfo.resonse.result);
  }

  /*---------| Verificar autenticacion |---------*/
  verifyAuth(): Observable<boolean> {
    if (!localStorage.getItem('token')) {
      return of(false);
    }

    return of(true);
  }

  /*----------| Cerrar la sesion |---------*/
  signOut() {
    localStorage.clear();
    this.storage.clear();
    // this.route
    // Usar el ruteo para regresar a la pagina de login !!
  }


  getAlumno():Observable<Alumno>{
    return this.alumno$.asObservable()
  }

  async selectAlumno(codigo){
    var promise = new Promise<any>((resolve,reject)=>{
      var alumno
      var token = JSON.parse(localStorage.getItem('token'))
      const paramsAlumno = JSON.stringify({
        param: { codigo: codigo }
      });
  
      this.authService.getInfoAlumno(paramsAlumno, token.Token)
        .subscribe((alumnInfo: AlumnoInfo) => {
          console.log('Informacion del alumno: ', alumnInfo);
          alumno = alumnInfo.resonse.result;

          //Emite la señal a todos los que estan suscritos al observable para que recarguen informacion
          this.alumno$.next(alumno)
          this.saveToken(alumnInfo);
          resolve(alumno)
        },error=>{
          reject(error)
        });
    })
    return promise
  }
  saveToken(alumnInf: AlumnoInfo) {
    console.log("Al: ",alumnInf)
    var token = JSON.parse(localStorage.getItem('token'))

    token.curso = alumnInf.resonse.result.Curso;
    token.codigo = alumnInf.resonse.result.Codigo;

    localStorage.setItem('token', JSON.stringify(token));
    this.storage.set('alumno', alumnInf.resonse.result);
  }
}
