import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { environment } from 'src/environments/environment';
import { doc, setDoc,deleteDoc } from "firebase/firestore"; 
import { PushNotifications } from '@capacitor/push-notifications';
import { deleteObject, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';
import { Token } from 'src/app/auth/interfaces/token.interface';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  async addDocument(object:any,collectionName){
    // Initialize Firebase
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const document = doc(collection(db, collectionName));
    await setDoc(document, object);
  }

  async documentExist(collectionName,field,value){
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, collectionName), where(field, "==", value));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty
  }

  async removeDocument(collectionName,field,value){
    // Initialize Firebase
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const q = query(collection(db, collectionName), where(field, "==", value));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(d=>{
      deleteDoc(d.ref);
    })
  }
  async removeDocumentByRef(collectionName,ref){
    // Initialize Firebase
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const docRef = doc(db, collectionName, ref);
    deleteDoc(docRef);
  }
  async getDocumentsFromCollection(collectionName){
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const notiRef = query(collection(db,collectionName));
    const notiSnapshot = await getDocs(notiRef);

    return notiSnapshot.docs.map(doc => {
      console.log("ID del documento: ", doc.id); // Agrega esta línea para obtener el ID del documento
      console.log("Datos del documento: ", doc.data());
      return { id: doc.id, ...doc.data() }; // Incluye el ID en los datos del documento
    });
      // console.log(notiSnapshot);
  }

  async editDocument(collectionName,id,value){
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const ref = doc(db, collectionName, id);
    return await updateDoc(ref,value);
  }

  async AddImageStorage(path:string,fileUpload: any) {
    let promise =  new Promise((resolve,reject)=>{
      const app = initializeApp(environment.firebaseConfig);
      const storage = getStorage(app);
      const storageRef = ref(storage, path+'/'+fileUpload.name);
      const reader = new FileReader();
      
        reader.onload = (event) => {
          const blob = new Blob([event.target.result], { type: fileUpload.type });
          uploadBytes(storageRef,blob).then((snapshot) => {
            resolve(snapshot)
            console.log('Uploaded an array!');
          },error=>{
            reject(error)
          });
          // You can now use the blob as needed, such as uploading it to a server
        };
        reader.readAsArrayBuffer(fileUpload);
    })
    return promise

  }
  async getImagesFromFireStore(path:string) {
    const app = initializeApp(environment.firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, path+'/');
    return await listAll(storageRef)
  }
  async removeImageFromFireStorare(path:string) {
    const app = initializeApp(environment.firebaseConfig);
    const storage = getStorage(app);
    const desertRef = ref(storage, path);

    return await deleteObject(desertRef)
  }

  
  requestPermission(){
        // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        //var requestPermission:boolean = JSON.parse(localStorage.getItem('requestPermission'));
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
  }


}
