import { Injectable } from '@angular/core';
import { query, collection, getDocs } from 'firebase/firestore';
import db from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  currentMessage = new BehaviorSubject<any>(null);

  constructor(private angularFireMess: AngularFireMessaging,private alertController:AlertController) { }

  async getNotifications() {
    const notiRef = query(collection(db,'notificaciones'));
    const notiSnapshot = await getDocs(notiRef);

    return notiSnapshot.docs.map(doc => {
      console.log("docs: ",doc.data())
      return {
        'id': doc.id, ...doc.data()
      }
    });
    // console.log(notiSnapshot);
  }

  /*-----| Request Token |-----*/
  requestPermission() {
    this.angularFireMess.requestToken
      .subscribe((token) => {
        console.log('Token generado por firebase mess: ', token);
      }, (err) => {
        console.log('Error en el permission notify: ', err);
      });
  }

  /*----------| Recibir notificacion |----------*/
  reciverMesaging() {
    this.angularFireMess.messages
      .subscribe((payload) => {
        console.log('new message received', payload);
        this.currentMessage.next(payload);
      });

  }

  /**
   *
   *
   * @param {*} header
   * @param {*} subHeader
   * @param {*} message
   * @param {*} okText - Texto del boton de confirmacion
   * @param {*} handler - Funcion a ejecutar al oprimir OK
   * @memberof MessagesService
   */
  async showAlert(header,subHeader,message,okText,handler) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      cssClass: 'custom-ion-alert', // Agrega la clase CSS personalizada aquí
      mode:"ios",
      buttons: [{
        text: okText,
        role: 'confirm',
        handler,
      }],
    });

    await alert.present();
  }
}
